define('ember-cli-countries/utils/countries', ['exports', 'ember', 'ember-cli-countries/enums/countries', 'ember-cli-countries/enums/calling-codes'], function (exports, _ember, _emberCliCountriesEnumsCountries, _emberCliCountriesEnumsCallingCodes) {
  exports.getCountryName = getCountryName;
  exports.getCountryCallingCode = getCountryCallingCode;
  exports.getAllCountryNames = getAllCountryNames;
  exports.getAllCountryCallingCodes = getAllCountryCallingCodes;
  var Logger = _ember['default'].Logger;

  function getCountryName(countryCode) {
    var name = _emberCliCountriesEnumsCountries['default'][countryCode];

    if (!name) {
      Logger.error('The country with code ' + countryCode + ' was not found');

      return null;
    }

    return name;
  }

  function getCountryCallingCode(countryCode) {
    var callingCode = _emberCliCountriesEnumsCallingCodes['default'][countryCode];

    if (!callingCode) {
      Logger.error('The country with code ' + countryCode + ' was not found');

      return null;
    }

    return callingCode;
  }

  function getAllCountryNames() {
    return _emberCliCountriesEnumsCountries['default'].toKeyValueJson();
  }

  function getAllCountryCallingCodes() {
    return _emberCliCountriesEnumsCallingCodes['default'].toKeyValueJson();
  }
});