define('ember-cli-countries/helpers/country-calling-code', ['exports', 'ember', 'ember-cli-countries/enums/calling-codes'], function (exports, _ember, _emberCliCountriesEnumsCallingCodes) {
  exports.countryCallingCode = countryCallingCode;
  var Helper = _ember['default'].Helper;
  var isEmpty = _ember['default'].isEmpty;

  function countryCallingCode(countryCode) {
    if (isEmpty(countryCode)) {
      return null;
    }

    return _emberCliCountriesEnumsCallingCodes['default'][countryCode];
  }

  exports['default'] = Helper.helper(countryCallingCode);
});