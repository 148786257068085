define('ember-cli-countries/helpers/country-name', ['exports', 'ember', 'ember-cli-countries/enums/countries'], function (exports, _ember, _emberCliCountriesEnumsCountries) {
  exports.countryName = countryName;
  var Helper = _ember['default'].Helper;
  var isEmpty = _ember['default'].isEmpty;

  function countryName(countryCode) {
    if (isEmpty(countryCode)) {
      return null;
    }

    return _emberCliCountriesEnumsCountries['default'][countryCode];
  }

  exports['default'] = Helper.helper(countryName);
});