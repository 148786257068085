define('ember-cli-countries/enums/types', ['exports', 'ember'], function (exports, _ember) {
    var EmberObject = _ember['default'].Object;
    exports['default'] = EmberObject.extend({
        toKeyValueJson: function toKeyValueJson() {
            var _this = this;

            return Object.keys(this).map(function (key) {
                return { key: key, value: _this[key] };
            });
        },

        values: function values() {
            var _this2 = this;

            return Object.keys(this).map(function (key) {
                return _this2[key];
            });
        }
    });
});